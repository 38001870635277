import React, { useState, useEffect } from 'react';
import axios from 'axios'; 
import './VerifiedMembers.css';
import { Link } from 'react-router-dom';
import { useNavigate, NavLink } from 'react-router-dom';

const VerifiedMembers = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeCard, setActiveCard] = useState(null);
  const [showRewards, setShowRewards] = useState(false);
  const [showMembership, setShowMembership] = useState(false);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [rewardAmount, setRewardAmount] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [verifiedMembers, setVerifiedMembers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userLevels, setUserLevels] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/404');
    } else {
      axios.get('http://sanaddigital.com/public/api/checkAdmin', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        if (response.data.is_admin) {
          setIsAdmin(true);
        } else {
          navigate('/404');
        }
      })
      .catch(error => {
        console.error('Error verifying admin status:', error);
        navigate('/404');
      });
    }

    const fetchVerifiedMembers = async () => {
      try {
        const token = localStorage.getItem('token'); 
        const response = await axios.get('http://sanaddigital.com/public/api/verifiedMembers', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setVerifiedMembers(response.data.VerifiedUsers);
      } catch (error) {
        console.error('Error fetching verified members:', error);
      }
    };

    fetchVerifiedMembers();
  }, []);

  useEffect(() => {
    const ytoken = localStorage.getItem('token');
    const fetchUserLevels = async () => {
      try {
        const response = await axios.get('http://sanaddigital.com/public/api/userLevels', {
          headers: {
            Authorization: `Bearer ${ytoken}`,
          },
        });
        setUserLevels(response.data.userLevels);
      } catch (error) {
        console.error('Error fetching user level', error);
      }
    };

    fetchUserLevels();
  }, []);

  const filteredMembers = verifiedMembers.filter(member =>
    member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    String(member.id_person).includes(searchTerm)
  );

  const toggleCard = (id) => {
    setActiveCard(activeCard === id ? null : id);
    setShowRewards(false);
    setShowMembership(false);
  };

  const handleSendRewardsClick = (e) => {
    e.stopPropagation();
    setShowRewards(!showRewards);
    setShowMembership(false);
  };

  const handleFreeMembershipClick = (e) => {
    e.stopPropagation();
    setShowMembership(!showMembership);
    setShowRewards(false);
  };

  const handleLevelSelect = (level) => {
    const newSelectedLevels = Array.from({ length: level }, (_, i) => i + 1); // تحديد المستوى المحدد مع جميع المستويات الأقل منه
    setSelectedLevels(newSelectedLevels);
  };

  const handleSaveLevels = async (memberId) => {
    const token = localStorage.getItem('token'); 

    try {
      const response = await axios.post(
        'http://sanaddigital.com/public/api/freeMembership',
        {
          user_id: memberId,
          level_numbers: selectedLevels, 
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        }
      );

      const newNotifications = [...notifications];
      if (response.data.successMessages) {
        newNotifications.push(...response.data.successMessages);
      }
      if (response.data.failedMessages) {
        newNotifications.push(...response.data.failedMessages);
      }
      setNotifications(newNotifications);

      setTimeout(() => setNotifications([]), 2000);
    } catch (error) {
      console.error('Error saving membership levels:', error);
      setNotifications(['Error saving membership levels.']);
      setTimeout(() => setNotifications([]), 2000);
    }
  };

  const handleSendReward = async (e, memberId) => {
    e.stopPropagation();
    const token = localStorage.getItem('token'); 

    if (!rewardAmount) {
      setNotifications(['Please enter a valid reward amount.']);
      setTimeout(() => setNotifications([]), 2000);
      return;
    }

    try {
      const response = await axios.post(
        'http://sanaddigital.com/public/api/sendRewards',
        {
          member_id: memberId, 
          reward_amount: rewardAmount, 
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        }
      );

      if (response.data.Success) {
        setNotifications(['Reward sent successfully!']);
      } else {
        setNotifications([response.data.Failed || 'Failed to send reward.']);
      }

      setRewardAmount(''); 
      setTimeout(() => setNotifications([]), 2000);
    } catch (error) {
      console.error('Error sending reward:', error);
      setNotifications(['Error sending reward.']);
      setTimeout(() => setNotifications([]), 2000);
    }
  };

  const goBack = () => {
    window.history.back();
  };

  if (!isAdmin) {
    return null;
  }

  return (
    <div style={{marginTop: '-65px'}} className="verified-members-container">
      <div className="back-arrow" onClick={goBack}>
        <i style={{color: "gray"}} className="fas fa-arrow-left"></i>
      </div>
      <h2 style={{color: "gray"}}>VERIFIED MEMBERS</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by name or ID..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>
      <div className="verified-members-list">
        {filteredMembers.map((member) => {
          // تحديد المستويات التي اشترك فيها المستخدم
          const memberLevels = userLevels
            .filter(ul => ul.user_id === member.id)
            .map(ul => ul.level);

          return (
            <div
              key={member.id}
              className={`verified-member-card ${activeCard === member.id ? 'active' : ''}`}
              onClick={() => toggleCard(member.id)}
            >
              <div className="verified-member-info">
                <div className="member-name2">{member.name}</div>
                <div className="member-id">ID person: {member.id_person}</div>
                <div className="member-date">Registered on: {new Date(member.created_at).toLocaleDateString()}</div>
              </div>
              <div className={`member-actions22 ${activeCard === member.id ? 'active' : ''}`}>
                <Link to={`/VerifiedUserProfile/${member.id}`} style={{textDecoration: "none", color: 'white'}} className="action-btn" onClick={(e) => e.stopPropagation()}>Profile</Link>
                <Link to={`/userTeamMembers/${member.id}`} style={{ textDecoration: "none", color: 'white' }} className="action-btn" onClick={(e) => e.stopPropagation()}>Team Members</Link>
                <Link to="" style={{textDecoration: "none", color: 'white'}} className="action-btn" onClick={handleSendRewardsClick}>Send Rewards</Link>
                <Link to="" style={{textDecoration: "none", color: 'white'}} className="action-btn" onClick={handleFreeMembershipClick}>Free Membership</Link>
                <Link to={`/UserTransactions/${member.id}`} style={{textDecoration: "none", color: 'white'}} className="action-btn" onClick={(e) => e.stopPropagation()}>Transactions</Link>
              </div>

              {showRewards && activeCard === member.id && (
                <div className="reward-input-container" onClick={(e) => e.stopPropagation()}>
                  <input
                    type="text"
                    value={rewardAmount}
                    onChange={(e) => setRewardAmount(e.target.value)}
                    placeholder="Enter amount in USDT"
                    className="reward-input"
                  />
                  <button className="send-reward-btn" onClick={(e) => handleSendReward(e, member.id)}>
                    Send
                  </button>
                </div>
              )}

              {showMembership && activeCard === member.id && (
                <div className="level-input-container" onClick={(e) => e.stopPropagation()}>
                  <div className="level-checkboxes">
                    {[1, 2, 3, 4, 5].map((level) => (
                      <div
                        key={level}
                        className={`level-box ${memberLevels.includes(level) || selectedLevels.includes(level) ? 'checked' : ''}`}
                        onClick={() => handleLevelSelect(level)}
                      >
                        Level {level}
                      </div>
                    ))}
                  </div>
                  <button className="save-level-btn" onClick={() => handleSaveLevels(member.id)}>
                    Save
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>

      {notifications.length > 0 && (
        <div className="notification-message">
          {notifications.map((msg, index) => (
            <div key={index}>{msg}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VerifiedMembers;