import React from 'react';
import './Home.css';
import { useState, useEffect, useRef } from 'react';
import "./responsive.css"
import Nav from '../Nav/Nav';
import Footer from '../Footer/Footer';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 
import Comment from '../comment';
import { Fade, Slide, Zoom, Roll } from 'react-awesome-reveal';
import { useInView } from 'react-intersection-observer';
import BackToUp from '../BackToTop';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import ReactPlayer from 'react-player';
const Home = () => {
  const [memberName, setMemberName] = useState(''); // لتخزين الاسم
  const [error, setError] = useState(null); // لتخزين أي أخطاء
  const [teamNumbers, setTeamNumbers] = useState(null); // لتخزين بيانات الفريق
  const { t } = useTranslation('home'); // تحديد Namespace
  const { ref: heroRef, inView: heroInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: textRef, inView: textInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: videoRef, inView: videoInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: workRef, inView: workInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: imageRef, inView: imageInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  

  useEffect(() => {
    const fetchMemberName = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('http://sanaddigital.com/public/api/memberName', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // تخزين الاسم المستلم في الحالة
        setMemberName(response.data.username || ' '); // تأكد أن `name` هو المفتاح الصحيح
      } catch (err) {
        setError(err.response?.data?.message || 'Error fetching member name');
        setMemberName(' ');
      }
    };

    fetchMemberName();
  }, []);

  useEffect(() => {
    const fetchTeamNumbers = async () => {
      try {
        const response = await axios.get('http://sanaddigital.com/public/api/teamNumbers');
        
        // تخزين البيانات المستلمة
        setTeamNumbers(response.data); 
      } catch (err) {
        setError(err.response?.data?.message || 'Error fetching team numbers');
      }
    };

    fetchTeamNumbers();
  }, []);

  useEffect(() => {
    if (t('direction') == 'ltr') {
      sliderRef.slickGoTo(0); // إعادة السلايدر إلى البداية
    }else {
      sliderRef.slickGoTo(4);
    }
  }, [t('direction')]);

  const { ref: faqRef, inView: faqInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    dots: false,
    infinite: false,
    
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: false,
    cssEase: 'ease-out', // إضافة تأثير سلس للانتقال
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024, // للأجهزة اللوحية
        settings: {
          slidesToShow: 3, // عرض كرتين
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          variableWidth: true,
        },
      },
      {
        breakpoint: 760, // للأجهزة اللوحية
        settings: {
          slidesToShow: 2, // عرض كرتين
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          variableWidth: true,
        },
      },
      {
        breakpoint: 480, // للهواتف
        settings: {
          slidesToShow: 1, // عرض كرت واحد
          slidesToScroll: 1,
          variableWidth: true,
          dots: false,
        },
      },
    ],
  };
  

  return (
    <div>
      <BackToUp/>
      <div className="home-container">
        <header className="hero">
          <div className="overlay"></div>
          <div className="content">
            <h1 className="hero-text">{t('welcome')} <br/> {t('hero_text')}</h1>
            <h2 className="dynamic-text">
              <span className="word w1">{t('dynamic_text_1')}</span>
              <span className="word w2">{t('dynamic_text_2')}</span>
            </h2>
            <p className="hero-description">{t('hero_description_1')}</p>
            <p className="hero-description">{t('hero_description_2')}</p>
            <p className="hero-description">{t('hero_description_3')}</p>
            <p className="hero-description">{t('hero_description_4')}</p>

          <div dir={t('direction')} >
            <div style={{ marginTop: '50px', display: 'flex', justifyContent: 'center', gap: '30px' }}>
            <div  className=" newBouu  desktop-center padding-top-50 wow animate__animated animate__fadeInUp animated">
                <a href="/Subscriptions" style={{ textDecoration: "none", borderRadius: '100px', userSelect: 'none'  }} className=" dire boxed-btn btn-work">{t('footer.page_subscriptions')}
                </a>
                
              </div>
              <div className=" newBouu  desktop-center padding-top-50 wow animate__animated animate__fadeInUp animated">
                <a href="/Wallet" style={{ textDecoration: "none", borderRadius: '100px', userSelect: 'none'  }} className=" dire boxed-btn btn-work">{t('footer.page_wallet')}
                </a>
              </div>
            </div>
            <div className="newBouu  desktop-center padding-top-50 wow animate__animated animate__fadeInUp animated">
                <a href="/ContactUs" style={{ textDecoration: "none", borderRadius: '100px', userSelect: 'none' }} className=" dire boxed-btn btn-work">{t('footer.page_contact_us2')}
                </a>
              </div>
          </div>
          </div>

          <div className="background-animation"></div>
        </header>
        <div style={{color: 'white', textAlign: 'center', color: 'black', marginTop: '40px'}}>
        <h3>{t('welcome2')}</h3>
        <p>{memberName}</p>
      </div>
        <div className="Carrying">
          <div>
               </div>
      <h3    style={{
      color: '#106C65',
      backgroundColor: '#d9f4e2',
      position: 'relative', // أو position: absolute لتجاهل العرض المحدود
      left: '51%',
      transform: 'translateX(-50%)',
      width: '100vw', // عرض الشاشة بالكامل
      textAlign: 'center', // إضافة مركزية للنص إذا لزم الأمر
      margin: '0 auto',
      padding: '10px'
    }}>{t('carryingGloryTitle')}</h3>
         
        <Fade left when={textInView}>
        <div style={{ marginTop: '20px' }}>
        <p>{t('startSpeech')}</p>
          <p>{t('weakMindsWarning')}</p>
          <p>{t('opportunityResponse1')}</p>
          <p>{t('opportunityResponse2')}</p>
          <p>{t('opportunityResponse3')}</p>
        
         
        </div>
        </Fade>
      </div>
      <Fade up when={textInView}>
      <div className="Carrying">
        <h4 style={{
      color: '#106C65',
      backgroundColor: '#d9f4e2',
      position: 'relative', // أو position: absolute لتجاهل العرض المحدود
      left: '51%',
      transform: 'translateX(-50%)',
      width: '100vw', // عرض الشاشة بالكامل
      textAlign: 'center', // إضافة مركزية للنص إذا لزم الأمر
      margin: '0 auto',
      padding: '10px'
    }}>{t('howToBeSuccessful')}</h4>
    <div className='video-slide' style={{marginTop: '20px',}}>    
      <ReactPlayer 
        url="/images/SanadDigital1.mp4"
        controls
        width="100%"
        height="auto"
        
      />

      </div>
      <div className="btn-wrapper desktop-center padding-top-30 wow animate__animated animate__fadeInUp animated">
                <NavLink to="/SignUp" style={{ textDecoration: "none", userSelect: 'none' }} className=" dire boxed-btn btn-work">{t('get_start_now')}</NavLink>
              </div>
  </div>
</Fade>
  <div className='Carrying'>
      <h3 style={{
      color: '#106C65',
      backgroundColor: '#d9f4e2',
      position: 'relative', // أو position: absolute لتجاهل العرض المحدود
      left: '51%',
      transform: 'translateX(-50%)',
      width: '100vw', // عرض الشاشة بالكامل
      textAlign: 'center', // إضافة مركزية للنص إذا لزم الأمر
      margin: '0 auto',
      padding: '10px'
    }}>{t('sanadDigitalOverview')}</h3>
      <Fade left when={textInView}>
      <div style={{marginTop: '20px'}}>
        <p>{t('subscribingMethod')}</p>
        <p>{t('noHiddenConditions')}</p>
        <p>{t('rewardsForActiveMembers')}</p>
        <h3 style={{
      color: '#106C65',
      backgroundColor: '#d9f4e2',
      position: 'relative', // أو position: absolute لتجاهل العرض المحدود
      left: '51%',
      transform: 'translateX(-50%)',
      width: '100vw', // عرض الشاشة بالكامل
      textAlign: 'center', // إضافة مركزية للنص إذا لزم الأمر
      margin: '0 auto',
      padding: '10px'
    }}>{t('howSanadWorks')}</h3>
    <br/>
        <p>{t('firstLevelSubscription')}</p>
        <p>{t('inviterReward')}</p>
        <p>{t('firstLevelInvitedReward')}</p>
        <p>{t('advancedLevelInvitedReward')}</p>
        <p>{t('distinguishedMemberReward')}</p>
        <p>{t('alwaysAWinner')}</p>
      </div>
      </Fade>
  </div>
  <Fade up when={textInView}>
  <div className='Carrying'>
    <h3 style={{
      color: '#106C65',
      backgroundColor: '#d9f4e2',
      position: 'relative', // أو position: absolute لتجاهل العرض المحدود
      left: '51%',
      transform: 'translateX(-50%)',
      width: '100vw', // عرض الشاشة بالكامل
      textAlign: 'center', // إضافة مركزية للنص إذا لزم الأمر
      margin: '0 auto',
      padding: '10px'
    }}>{t('howsanaddigitalwork')}</h3>
    <div className='video-slide' style={{marginTop: '20px',}}>    
    <ReactPlayer 
            url="/images/SanadDigital2.mp4" 
            controls 
            width="100%" 
            height="auto"
        
          />

      </div>
      <div className="btn-wrapper desktop-center padding-top-30 wow animate__animated animate__fadeInUp animated">
                <NavLink to="/SignUp" style={{ textDecoration: "none" , background: '#f5861f', userSelect: 'none' }} className=" dire boxed-btn btn-work">{t('footer.freemember2')} {t('footer.freemember')}</NavLink>
              </div>
  </div>
  </Fade>
<div dir={t('direction')}  className='FirstDiv2 padding-top-20 padding-bottom-20'>
<h3 style={{
      color: '#106C65',
      backgroundColor: '#d9f4e2',
      position: 'relative', // أو position: absolute لتجاهل العرض المحدود
      left: '51%',
      transform: 'translateX(-50%)',
      width: '100vw', // عرض الشاشة بالكامل
      textAlign: 'center', // إضافة مركزية للنص إذا لزم الأمر
      margin: '0 auto',
      padding: '10px'
    }}>{t('howToCreateFreeMembership')}</h3>
<div className='row' style={{display: 'flex' , justifyContent: 'center', marginTop: '30px'}}>
  <div dir={t('direction')} className="FirstDiv col-xl-6 col-lg-5 col-md-12 col-sm-12" ref={textRef}>
              <Fade left when={textInView}>
              <div dir={t('direction')} className="scrollable-container">
  <p>{t('step1')}</p>
  <p>{t('step2')}</p>
  <p>{t('step3')}</p>
  <p>{t('step4')}</p>
  <p>{t('step5')}</p>
  <p>{t('step6')}</p>
  <p>{t('profilePicture')}</p>
  <p>{t('fullNameCheck')}</p>
  <p>{t('emailCheck')}</p>
  <p>{t('idNumberCheck')}</p>
  <p>{t('whatsappCheck')}</p>
  <p>{t('idUpload')}</p>
  <p>{t('step7')}</p>
  <p>{t('step8')}</p>
</div>

              </Fade>
            </div>
  
            
            <div className='Carrying2 col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-4'>
  <Fade up when={textInView}>
    <div className='video-slide'>    
    <ReactPlayer 
            url="/images/SanadDigital3.mp4" 
            controls 
            width="100%" 
            height="auto"
            
          />

      </div>
      <div className="btn-wrapper desktop-center padding-top-20 wow animate__animated animate__fadeInUp animated">
                <NavLink to="/SignUp" style={{ textDecoration: "none" , background: '#f5861f', userSelect: 'none' }} className=" dire boxed-btn btn-work">{t('footer.freemember2')} {t('footer.freemember')}</NavLink>
              </div>
         </Fade>      
  </div>
 
 </div>
</div>
<div dir={t('direction')}  className='FirstDiv2 padding-top-20 padding-bottom-40'>
<h3 style={{
      color: '#106C65',
      backgroundColor: '#d9f4e2',
      position: 'relative', // أو position: absolute لتجاهل العرض المحدود
      left: '51%',
      transform: 'translateX(-50%)',
      width: '100vw', // عرض الشاشة بالكامل
      textAlign: 'center', // إضافة مركزية للنص إذا لزم الأمر
      margin: '0 auto',
      padding: '10px'
    }}>
  {t('depositAndWithdrawTitle')}
</h3>
<div className='row' style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
  <div dir={t('direction')} className="FirstDiv col-xl-6 col-lg-5 col-md-12 col-sm-12 mt-2" ref={textRef}>
    <Fade left when={textInView}>
      <div  dir={t('direction')} className="scrollable-container">
        <p>
        <span style={{fontSize: '17px', fontWeight: 'bold', color: '#106C65'}}>{t('firstDepositInstructions')}</span>
           <br />
          {t('firstStep')} <a href="https://app.cryptomus.com/signup/?ref=7QbPEG">Cryptomas</a> <br />
          {t('secondStep')} <br />
          {t('thirdStep')} <br />
          {t('fourthStep')} <br />
          {t('fifthStep')} <br />
        </p>
        <p>
          <span style={{fontSize: '17px', fontWeight: 'bold', color: '#106C65'}}>{t('secondWithdrawalInstructions')}</span>
           <br />
          {t('firstWithdrawalStep')} <br />
          {t('secondWithdrawalStep')} <br />
          {t('thirdWithdrawalStep')} <br />
          {t('fourthWithdrawalStep')} <br />
          {t('fifthWithdrawalStep')} <br />
        </p>
      </div>
    </Fade>
            </div>
  
           
            <div className='Carrying2 col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-4'>
               <Fade up when={textInView}>
    <div className='video-slide'>    
    <ReactPlayer 
            url="/images/SanadDigital4.mp4" 
            controls 
            width="100%" 
            height="auto"
        
          />

      </div>
      <div className="btn-wrapper desktop-center padding-top-20 wow animate__animated animate__fadeInUp animated">
                <a href="https://app.cryptomus.com/signup/?ref=7QbPEG" style={{ textDecoration: "none" , userSelect: 'none',}} className=" dire boxed-btn btn-work">{t("CreateCryptomusaccount")}</a>
              </div>
     </Fade>         
  </div>
  
 </div>
</div>

<div className='Carrying'>
    <h3 style={{
      color: '#106C65',
      backgroundColor: '#d9f4e2',
      position: 'relative', // أو position: absolute لتجاهل العرض المحدود
      left: '51%',
      transform: 'translateX(-50%)',
      width: '100vw', // عرض الشاشة بالكامل
      textAlign: 'center', // إضافة مركزية للنص إذا لزم الأمر
      margin: '0 auto',
      padding: '10px'
    }}>{t("Howtoactivatethesubscription")}</h3>
    <div style={{marginTop: '20px'}}>
      <p>{t("Howtoactivatethesubscriptiondes")}</p>
    </div>

  </div>
  <div  className='Carrying' style={{textAlign: 'center', maxWidth: '90%'}}>
  <Slider
  style={{ textAlign: "center" }}
  ref={(slider) => {
    sliderRef = slider;
  }}
  
  {...{
    ...settings,
    rtl: t('direction') === 'rtl', // يجعل السلايدر يتحرك من اليمين إلى اليسار في حال اللغة العربية
    ltr: t('direction') === 'ltr' , // يجعل السلايدر يتحرك من اليمين إلى اليسار في حال اللغة العربية
  }}

>
  <div className="custom-slider-item">
    <div className="card-header-area"></div>
    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
       <div className="logo-wrapper">
      <img src="/images/logo.png" alt="logo" />
    </div>
    </div>
   
    <div className="card-content-area">
      <h3 className="card-title-text">{t("level_1")}</h3>
      <p className="card-price-text">{t("level_1_usdt")}</p>
      <button className="card-action-btn"><a href="/Subscriptions">{t("subscribe_button")}</a></button>
    </div>
  </div>
  <div className="custom-slider-item">
    <div className="card-header-area"></div>
    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
       <div className="logo-wrapper">
      <img src="/images/logo.png" alt="logo" />
    </div>
    </div>
    <div className="card-content-area">
      <h3 className="card-title-text">{t("level_2")}</h3>
      <p className="card-price-text">{t("level_2_usdt")}</p>
      <button className="card-action-btn"><a href="/Subscriptions">{t("subscribe_button")}</a></button>
    </div>
  </div>
  <div className="custom-slider-item">
    <div className="card-header-area"></div>
    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
       <div className="logo-wrapper">
      <img src="/images/logo.png" alt="logo" />
    </div>
    </div>
    <div className="card-content-area">
      <h3 className="card-title-text">{t("level_3")}</h3>
      <p className="card-price-text">{t("level_3_usdt")}</p>
      <button className="card-action-btn"><a href="/Subscriptions">{t("subscribe_button")}</a></button>
    </div>
  </div>
  <div className="custom-slider-item">
    <div className="card-header-area"></div>
    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
       <div className="logo-wrapper">
      <img src="/images/logo.png" alt="logo" />
    </div>
    </div>
    <div className="card-content-area">
      <h3 className="card-title-text">{t("level_4")}</h3>
      <p className="card-price-text">{t("level_4_usdt")}</p>
      <button  className="card-action-btn"><a  href="/Subscriptions">{t("subscribe_button")}</a></button>
    </div>
  </div>
  <div className="custom-slider-item">
    <div className="card-header-area"></div>
    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
       <div className="logo-wrapper">
      <img src="/images/logo.png" alt="logo" />
    </div>
    </div>
    <div className="card-content-area">
      <h3 className="card-title-text">{t("level_5")}</h3>
      <p className="card-price-text">{t("level_5_usdt")}</p>
      <button className="card-action-btn"><a href="/Subscriptions">{t("subscribe_button")}</a></button>
    </div>
  </div>
</Slider>


  </div>
 
  
        <div className="batter-work-area bg-image padding-bottom-200 padding-top-200" style={{ backgroundImage: 'url(./images/businesstech/bg-02.png)' }} ref={workRef}>
          <Slide direction="right" when={workInView}>
            <div className="containerhmg">
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <div className=" desktop-center padding-bottom-50 wow animate__animated animate__fadeInUp animated">
                    <h1 style={{ color: 'white' }}><span>{t('safety_title')}</span></h1>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="work-single-item-04">
                    <div className="icon-wrap">
                      <div className="icon">
                        <i className="fa-solid fa-circle-user"></i>
                      </div>
                    </div>
                    <div className="content">
                      <h4 className="title">{t('create_membership')}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="work-single-item-04">
                    <div className="icon-wrap">
                      <div className="icon">
                        <i className="fa-solid fa-money-bill-1-wave fa-bounce"></i>
                      </div>
                    </div>
                    <div className="content">
                      <h4 className="title">{t('topup_balance')}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="work-single-item-04">
                    <div className="icon-wrap">
                      <div className="icon">
                        <i className="fa-solid fa-handshake"></i>
                      </div>
                    </div>
                    <div className="content">
                      <h4 className="title">{t('activate_subscription')}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="work-single-item-04">
                    <div className="icon-wrap">
                      <div className="icon">
                        <i className="fa-solid fa-hand-holding-dollar fa-beat"></i>
                      </div>
                    </div>
                    <div className="content">
                      <h4 className="title">{t('claim_rewards')}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-wrapper desktop-center padding-top-50 wow animate__animated animate__fadeInUp animated">
                <NavLink to="/SignUp" style={{ textDecoration: "none" }} className=" dire boxed-btn btn-work">{t('get_start_now')}</NavLink>
              </div>
            </div>
          </Slide>
        </div>

       
    <div class="call-to-action-area-01 bg-image padding-top-120" style={{backgroundImage: 'url(/images/businesstech/bg-03.png)',}}>
        <div class="container" style={{justifyContent: 'center', marginTop: '90px'}}>
            <div class="call-to-action-inner style-08">
               
                <div  class="row justify-content-center mb-lg-5">
                    <div class="col-lg-9">
                    
                        <div class="single-counterup-01 white">
                         
                                <div class="content">
                                  <h2  class="title">{t("SanadDigitalMembers")}</h2>
                                  {teamNumbers ? (
                                       <div class="count-wrap"><span class="count-num">{teamNumbers.allMembers}</span></div>
                                    ) : 0}
                                    
                                </div>
                                
                            </div>
                            
                    </div>
                   
                </div>
                
                <div dir={t('direction')} class="row justify-content-around">
                    <div class="counter-item-list ml-xl-3">
                        <div class="flex-grow-1 col-xl-3 col-lg-3 col-6 col-md-4  text-bg-warning">
                            <div class="single-counterup-01 white">
                                <div class="content">
                                    <h4 class="title">{t("level_1")}</h4>
                                    {teamNumbers ? (
                                       <div class="count-wrap"><span class="count-num">{teamNumbers.level1Members}</span></div>
                                    ) : 0}
                                   
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1 col-xl-2 col-lg-3 col-6 col-md-4  text-bg-warning">
                            <div class="single-counterup-01 white">
                                <div class="content">
                                  <h4 class="title">{t("level_2")}</h4>
                                  {teamNumbers ? (
                                       <div class="count-wrap"><span class="count-num">{teamNumbers.level2Members}</span></div>
                                    ) : 0}
                                   
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1 col-xl-2 col-lg-3 col-4 col-md-4  text-bg-warning ">
                            <div class="single-counterup-01 white">
                                <div class="content">
                                  <h4 class="title">{t("level_3")}</h4>
                                  {teamNumbers ? (
                                       <div class="count-wrap"><span class="count-num">{teamNumbers.level3Members}</span></div>
                                    ) : 0}
                                    
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1 col-xl-2 col-lg-3 col-4 col-md-4  text-bg-warning ">
                            <div class="single-counterup-01 white">
                                <div class="content">
                                  <h4 class="title">{t("level_4")}</h4>
                                  {teamNumbers ? (
                                       <div class="count-wrap"><span class="count-num">{teamNumbers.level4Members}</span></div>
                                    ) : 0}
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1 col-xl-2 col-lg-3 col-4 col-md-4  text-bg-warning ">
                            <div class="single-counterup-01 white">
                                <div class="content">
                                  <h4 class="title">{t("level_5")}</h4>
                                  {teamNumbers ? (
                                       <div class="count-wrap"><span class="count-num">{teamNumbers.level5Members}</span></div>
                                    ) : 0}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
  
        <div className="accoridions padding-top-120 padding-bottom-120">
    <div className="containerhmg">
        <div className="row justify-content-center padding-bottom-50">
            <div className="col-lg-8 col-md-12">
                <div className="section-title desktop-center">
                    <h2 style={{ color: "#32A8A2", marginTop: "10px" }} className="title">
                        {t('short_inquiries')}
                    </h2>
                </div>
            </div>
        </div>
        <div className="row justify-content-center">
            <div className="col-lg-8">
                <div  className="accordion-wrapper">
                    <div dir={t('direction')}  id="accordion">
                        <div className="carddd">
                            <div className="carddd-header" id="headingOwo">
                                <h5 className="mb-0">
                                    <a  className="collapsed white" role="button" data-toggle="collapse" data-target="#collapseOwo" aria-expanded="false" aria-controls="collapseOwo">
                                        {t('faq_question_1')}
                                    </a>
                                </h5>
                            </div>
                            <div id="collapseOwo" className="collapse show" data-parent="#accordion">
                                <div className="carddd-body style-01">
                                    {t('faq_answer_1')}
                                </div>
                            </div>
                        </div>
                        <div className="carddd">
                            <div className="carddd-header" id="headingTwo">
                                <h5 className="mb-0">
                                    <a className="collapsed white" role="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseOwo">
                                        {t('faq_question_2')}
                                    </a>
                                </h5>
                            </div>
                            <div id="collapseTwo" className="collapse" data-parent="#accordion">
                                <div className="carddd-body style-01">
                                    {t('faq_answer_2')}
                                </div>
                            </div>
                        </div>
                        <div className="carddd">
                            <div className="carddd-header" id="headingThree">
                                <h5 className="mb-0">
                                    <a className="collapsed white" role="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseOwo">
                                        {t('faq_question_3')}
                                    </a>
                                </h5>
                            </div>
                            <div id="collapseThree" className="collapse" data-parent="#accordion">
                                <div className="carddd-body style-01">
                                    {t('faq_answer_3')}
                                </div>
                            </div>
                        </div>
                        <div className="carddd">
                            <div className="carddd-header" id="headingFour">
                                <h5 className="mb-0">
                                    <a className="collapsed white" role="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        {t('faq_question_4')}
                                    </a>
                                </h5>
                            </div>
                            <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                <div className="carddd-body style-01">
                                    {t('faq_answer_4')}
                                </div>
                            </div>
                        </div>
                        <div className="carddd">
                            <div className="carddd-header" id="headingFive">
                                <h5 className="mb-0">
                                    <a className="collapsed white" role="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        {t('faq_question_5')}
                                    </a>
                                </h5>
                            </div>
                            <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                <div className="carddd-body style-01">
                                    {t('faq_answer_5')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

      </div>
    </div>
  );
  
};

export default Home;
